.login {
  height: 70%;
  display: flex;
  justify-content: center;
}

.login__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 100%;
  font-weight: 500;
  font-size: 15px;
  padding: 30px;
  border: 1px solid #ccc;
  /* text-align: center; */
}

.login__form input {
  padding: 4px;
  width: 300px;
  /* max-width: 300px; */
}

.login__email,
.login__password {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.login__btn {
  width: 50%;
}

@media only screen and (max-width: 768px) {
  .login__form {
    width: 80%;
  }
}

@media only screen and (max-width: 400px) {
  .login__form input {
    max-width: 200px;
  }
  .login__btn {
    width: 80%;
  }
}
