.register {
  height: 75%;
  display: flex;
  justify-content: center;
}

.register__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 100%;
  font-weight: 500;
  font-size: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  /* text-align: center; */
}

.register__form input {
  padding: 4px;
  width: 300px;
  /* max-width: 300px; */
}

.register__name,
.register__email,
.register__password,
.register__userName {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.btn {
  font-size: 18px;
  font-weight: 500;
  padding: 10px;
  background-color: #ff9448;
  border-radius: 10px;
  width: 100%;
  outline: none;
  cursor: pointer;
  border: none;
}

.register__login {
  margin: 10px;
}

.register__message {
  margin: 0;
}

.register__warningMessage {
  color: red;
  font-weight: bold;
}

.register__hidden {
  display: none;
}

@media only screen and (max-width: 800px) {
  .register {
    height: 82%;
    overflow: scroll;
  }
  .register__form {
    width: 80%;
    padding: 5px;
  }
  .register__btn {
    padding: 5px;
    width: 50%;
  }
}

@media only screen and (max-width: 400px) {
  .register__form input {
    max-width: 200px;
  }
}
