.contact__form {
  height: 100%;
}
#contact {
  height: 100%;
  padding-bottom: 5%;
  overflow: scroll;
  text-align: center;
}
#contact::-webkit-scrollbar {
  width: 0.4rem;
  color: #ccc;
}
.contact-form {
  display: grid;
  justify-content: center;
  margin: 8%;
  grid-gap: 2rem;
}
.contact-input {
  padding: 18px;
  border: 2px solid var(--secondary-color);
  border-radius: 7px;
  width: 400px;
  outline: none;
}
.contact-message {
  padding: 10px;
  border: 2px solid var(--secondary-color);
  outline: none;
  border-radius: 7px;
  height: 200px;
}
input::placeholder,
textarea::placeholder {
  font-size: 15px;
  font-weight: bold;
}

.btn:hover {
  box-shadow: 0 4px 25px rgb(14 36 49 / 15%);
}
.btn-submit {
  float: right;
  position: relative;
  right: -250px;
  bottom: 10px;
  width: 150px;
  height: 45px;
  font-size: 17px;
  margin-bottom: 20px;
}
.btn-contact {
  width: 150px;
  height: 45px;
  font-size: 17px;
}
.section-hidden {
  opacity: 0;
  transform: translateY(18rem);
}
.section {
  transition: all 1s;
}
.footer {
  margin-top: 60px;
  height: 40vh;
  background-color: #0e2431;
  color: var(--primary-bg-color);
}
.footer-container {
  text-align: center;
  padding: 3%;
  font-weight: 100;
  display: grid;
  grid-gap: 2rem;
}
.active::after {
  content: "";
  display: block;
  width: 100%;
  height: 3px;
  margin-top: 4px;
  background-color: var(--primary-color);
}

@media (max-width: 558px) {
  .contact-input {
    width: 300px;
  }
  .btn-contact {
    right: -150px;
  }
  .btn-submit {
    right: -150px;
  }
}

@media (max-width: 360px) {
  .contact-input,
  .contact-message {
    width: 100%;
  }
  .btn-contact {
    left: 20%;
  }
  .btn-submit {
    right: -70px;
  }
}
