.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
  padding: 20px;
  width: 100%;
  max-height: 400px;
  min-width: 100px;
  z-index: 1;
  border-radius: 30px;
  background: radial-gradient(circle, #f9b187, #ffffff);
  /* flex: wrap; */
}

.product__cart {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.product img {
  min-height: 200px;
  width: 100%;
  object-fit: contain;
  margin-bottom: 15px;
}

.product__info {
  width: 100%;
  height: 100px;
  margin-bottom: 15px;
}

.product__pricerate {
  display: flex;
}

.product__price {
  margin-right: 10px;
}
