.nav {
  display: flex;
}
.nav__container {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  font-weight: bold;
  flex: 1;
}
.nav__link,
.nav__link:hover {
  color: #000;
  text-decoration: none;
}
.nav__logo {
  font-weight: bold;
  padding: 10px 20px;
  font-size: 20px;
  cursor: pointer;
}

.nav__option {
  flex: 0.6;
  padding-left: 20%;
}
.nav__option ul {
  display: flex;
  list-style: none;
  justify-content: space-around;
  font-weight: bold;
}
li {
  cursor: pointer;
}

.nav__account {
  font-weight: bold;
  padding: 0 10px;
  cursor: pointer;
}

.nav__shoppingCart {
  padding: 10px;
  border-radius: 50%;
  background-color: #fff5d7;
  cursor: pointer;
  padding-bottom: 10px;
}
.cart-hidden {
  display: none;
}

@media only screen and (max-width: 768px) {
  .nav__container {
    flex-direction: column;
    position: absolute;
    background-color: #ffebc1;
    right: 0%;
    padding-bottom: 100%;
    padding-right: 0;
    padding-left: 20px;
    margin-top: 40px;
    width: 40%;
    z-index: 100;
    transform: translateX(0%);
    transition: all 1s ease-in;
  }

  .nav__option {
    padding: 0;
  }

  ul {
    flex-direction: column;
  }

  .short-hidden {
    display: none;
    transition: all 1s;
    opacity: 0;
    /* transform: translateX(100%); */
  }
  .nav {
    justify-content: space-between;
  }
  .cart-hidden {
    display: block;
    margin: 5px 10px;
    /* padding: 10px; */
  }
}

.nav__colorChange {
  color: #ff9447;
}

@media (max-width: 300px) {
  .nav__container {
    width: 100%;
  }
}
