* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  overflow: hidden;
}
body {
  font-family: "Poppins", sans-serif;
  overflow: hidden;
  height: 100vh;
}

.App {
  background: radial-gradient(circle, #ff9447, #fdd3b6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.rights {
  text-align: center;
}
