.oneProduct {
  padding: 20px;
  height: 90%;
}

.oneProduct__container {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.oneProduct img {
  max-width: 300px;
  height: 300px;
}

.oneProduct__info {
  flex: 1;
  text-align: center;
}

.btn__cart {
  width: 25px;
  height: 25px;
  font-size: 20px;
  outline: none;
  margin-left: 20px;
  margin-right: 20px;
  background-color: #e0e9f0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.oneProduct__addToCart {
  display: flex;
  justify-content: center;
}

.btn-addToCart {
  width: 23%;
  padding: 5px;
  border-radius: 0;
  margin: 20px;
}

@media (max-width: 600px) {
  .oneProduct__container {
    flex-direction: column;
    overflow: scroll;
    align-items: center;
  }

  .btn-addToCart {
    width: 50%;
  }
}
